import {
   Box,
   Button,
   HStack,
   Link,
   Popover,
   PopoverArrow,
   PopoverBody,
   PopoverCloseButton,
   PopoverContent,
   PopoverHeader,
   PopoverTrigger,
   Select,
   SimpleGrid,
   Text,
   useDisclosure,
} from '@chakra-ui/react';
import { useTranslations } from '@ifixit/i18n';
import { type AddToCartEventType, type CartLineItem, useAddToCart } from '@ifixit/shopify-cart-sdk';
import { useEffect, useState, type ElementType } from 'react';
import { useCartDrawer } from '../cart';
import { ProductVariantPrice } from '../commerce';
import type { MerchImageComponent, MerchProductVariant } from './types';
import { useOrbitShirt } from './hooks/use-orbit-shirt';

type MerchBannerProps = {
   analytics: { eventType: AddToCartEventType };
   ImageComponent: MerchImageComponent;
   linkComponent?: ElementType;
   localeCode: string;
};

const nextFontSizeSm = '14px'; // Bypasses root font size difference between NextJS and Carpenter

export function MerchBanner({
   analytics,
   ImageComponent,
   linkComponent,
   localeCode,
}: MerchBannerProps) {
   const { onClose: onCartClose } = useCartDrawer();
   const { onOpen, onClose: onPopoverClose, isOpen } = useDisclosure();
   const shirt = useOrbitShirt();
   const t = useTranslations('components.MerchBanner');
   const [selectedVariant, setSelectedVariant] = useState<MerchProductVariant | null>(
      shirt?.variants[0] ?? null
   );
   useEffect(() => {
      if (shirt?.variants) {
         setSelectedVariant(shirt.variants[0]);
      }
   }, [shirt?.variants]);
   if (shirt == null || selectedVariant == null) {
      return null;
   }
   const href = selectedVariant.lineItem.url;
   return (
      <Popover isOpen={isOpen} onOpen={onOpen} onClose={onPopoverClose}>
         <PopoverTrigger>
            <Button variant="unstyled" height="min-content" width="100%">
               <HStack
                  bgColor="brand.100"
                  borderColor="brand.200"
                  borderWidth="1px"
                  p="3"
                  rounded="md"
                  justifyContent="right"
               >
                  <Text fontSize={nextFontSizeSm}>{t('addAnOrbitTShirt')}</Text>
                  <ProductVariantPrice
                     price={selectedVariant.lineItem.price}
                     compareAtPrice={selectedVariant.lineItem.compareAtPrice}
                     proPricesByTier={selectedVariant.proPricesByTier}
                     showDiscountLabel={false}
                     localeCode={localeCode}
                     size="small"
                     alignSelf="auto"
                  />
                  <Box
                     boxSize="64px"
                     border="1px solid"
                     borderColor="gray.200"
                     borderRadius="md"
                     overflow="hidden"
                  >
                     {shirt.imageSrc && (
                        <ImageComponent src={shirt.imageSrc} alt={shirt.imageAlt ?? undefined} />
                     )}
                  </Box>
               </HStack>
            </Button>
         </PopoverTrigger>
         <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>
               <Link as={linkComponent} href={href} onClick={onCartClose}>
                  {shirt.title}
               </Link>
            </PopoverHeader>
            <PopoverBody>
               <SimpleGrid columns={2} spacing="2.5" ml="auto">
                  <ApparelSizeVariantSelector
                     variants={shirt.variants}
                     selected={selectedVariant}
                     setSelected={setSelectedVariant}
                  />
                  <BuyButton
                     analytics={analytics}
                     lineItem={selectedVariant.lineItem}
                     onClick={onPopoverClose}
                     shopifyHandle={shirt.handle}
                  />
               </SimpleGrid>
            </PopoverBody>
         </PopoverContent>
      </Popover>
   );
}

function ApparelSizeVariantSelector({
   variants,
   selected,
   setSelected,
}: {
   variants: MerchProductVariant[];
   selected: MerchProductVariant;
   setSelected: (_: MerchProductVariant) => void;
}) {
   return (
      <Select
         value={selected.apparelSize}
         onChange={event => {
            const newSelection = variants.find(v => v.apparelSize === event.target.value);
            if (newSelection) {
               setSelected(newSelection);
            }
         }}
         overflow="hidden"
         size="sm"
         textOverflow="ellipsis"
      >
         {variants.map(variant => (
            <option key={variant.apparelSize} value={variant.apparelSize}>
               {variant.apparelSize}
            </option>
         ))}
      </Select>
   );
}

function BuyButton({
   analytics,
   lineItem,
   onClick,
   shopifyHandle,
}: {
   analytics: { eventType: AddToCartEventType };
   lineItem: CartLineItem;
   onClick: () => void;
   shopifyHandle: string;
}) {
   const { addToCart, enabled } = useAddToCart(analytics);
   const t = useTranslations('CartPage');
   return (
      <Button
         isDisabled={!enabled}
         isLoading={isAddingToCart()}
         onClick={() => {
            addToCart.mutate({
               analytics: {
                  type: 'product',
                  currentItemCode: lineItem.itemcode,
                  handle: shopifyHandle,
                  variantid: lineItem.shopifyVariantId,
               },
               lines: [lineItem],
            });
            onClick();
         }}
         size="sm"
         variant="cta"
      >
         {t('addToCart')}
      </Button>
   );

   function isAddingToCart() {
      const isItem =
         addToCart.variables?.analytics.type === 'product' &&
         addToCart.variables.analytics.currentItemCode === lineItem.itemcode;
      return addToCart.isLoading && isItem;
   }
}
