import {
   Alert,
   AlertDescription,
   AlertTitle,
   Badge,
   Box,
   Button,
   Drawer,
   DrawerBody,
   DrawerCloseButton,
   DrawerContent,
   DrawerFooter,
   DrawerHeader,
   DrawerOverlay,
   Flex,
   Heading,
   HStack,
   SimpleGrid,
   Skeleton,
   Spinner,
   Text,
   VisuallyHidden,
} from '@chakra-ui/react';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { formatMoney } from '@ifixit/helpers';
import { FaIcon, Flag, FlagCountryCode } from '@ifixit/icons';
import { useCart } from '@ifixit/shopify-cart-sdk';
import type { ElementType } from 'react';
import { Collapse, Fade, Slide } from '../../animations';
import { useIsMountedState } from '../../hooks';
import { CartLineItems } from '../cart-line-items';
import { CrossSell, type CrossSellProduct } from '../cross-sell';
import type { CartImageComponent } from '../types';
import { CartDrawerTrigger } from './CartDrawerTrigger';
import { CartEmptyState } from './CartEmptyState';
import { useCartDrawer } from './hooks/useCartDrawer';
import { ShopifyCheckoutButton } from './shopify-checkout-button';
import { CartPurchaseBanner, BannerTextComponent } from '../../commerce';
import { useTranslations } from '@ifixit/i18n';
import { MerchBanner } from '../../merch/merch-banner';

interface CartDrawerProps {
   crossSellItems: CrossSellProduct[];
   ImageComponent: CartImageComponent;
   linkComponent?: ElementType;
   supportLinkComponent: ElementType;
   localeCode: string;
   flagCode?: FlagCountryCode;
   viewCartUrl: string;
   cartBanner?: {
      cartQualifies: boolean;
      cartTotalThreshold: number;
      bannerText: BannerTextComponent;
   };
}

export function CartDrawer({
   crossSellItems,
   ImageComponent,
   linkComponent,
   localeCode,
   supportLinkComponent: SupportLinkComponent,
   flagCode,
   viewCartUrl,
   cartBanner,
}: CartDrawerProps) {
   const { isOpen, onOpen, onClose, onViewCart } = useCartDrawer();
   const isMounted = useIsMountedState();
   const cart = useCart();
   const t = useTranslations();

   const isCartEmpty = cart.isFetched && !cart.data?.hasItemsInCart;
   return (
      <>
         <CartDrawerTrigger onClick={onOpen} hasItemsInCart={cart.data?.hasItemsInCart} />
         {isMounted && (
            <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="sm">
               <DrawerOverlay />
               <DrawerContent color="gray.800" data-testid="cart-drawer" fontSize="md">
                  <DrawerHeader borderBottomWidth={1} position="relative">
                     <DrawerCloseButton
                        top="50%"
                        transform="translateY(-50%)"
                        data-testid="cart-drawer-close"
                     />
                     <HStack align="center">
                        {flagCode && <Flag code={flagCode} />}
                        <Heading size="sm" lineHeight="normal" my="0">
                           {t('CartPage.shoppingCart')}
                        </Heading>
                        {(cart.data != null || !cart.isError) && (
                           <Badge
                              borderRadius="full"
                              variant="subtle"
                              colorScheme="gray"
                              boxSize="6"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              bg="gray.100"
                              color="gray.400"
                           >
                              {cart.isLoading ? (
                                 <Spinner size="xs" />
                              ) : (
                                 <>
                                    <VisuallyHidden>{t('CartPage.numItems')}</VisuallyHidden>
                                    <Box as="span" data-testid="cart-drawer-item-count">
                                       {cart.data?.totals.itemsCount ?? 0}
                                    </Box>
                                 </>
                              )}
                           </Badge>
                        )}
                     </HStack>
                  </DrawerHeader>

                  <DrawerBody p="0" data-testid="cart-drawer-body" position="relative">
                     {cart.isError && (
                        <Alert
                           status="error"
                           variant="subtle"
                           flexDirection="column"
                           alignItems="center"
                           justifyContent="center"
                           textAlign="center"
                           height="200px"
                        >
                           <FaIcon icon={faCircleExclamation} h="10" color="red.500" />
                           <AlertTitle mt={4} mb={1} fontSize="lg">
                              {t('CartPage.Error.fetch')}
                           </AlertTitle>
                           <AlertDescription maxWidth="sm">
                              {t.rich('CartPage.Error.reload', {
                                 link: chunks => (
                                    <SupportLinkComponent>{chunks}</SupportLinkComponent>
                                 ),
                              })}
                           </AlertDescription>
                        </Alert>
                     )}
                     {cart.data?.hasItemsInCart && (
                        <>
                           <Box m="3">
                              <MerchBanner
                                 analytics={{ eventType: 'Cart Drawer Merch' }}
                                 ImageComponent={ImageComponent}
                                 linkComponent={linkComponent}
                                 localeCode={localeCode}
                              />
                           </Box>
                           {cartBanner && (
                              <Box m="3" data-testid="cart-drawer-purchase-banner">
                                 <CartPurchaseBanner
                                    cart={cart.data}
                                    cartQualifies={cartBanner.cartQualifies}
                                    cartTotalThreshold={cartBanner.cartTotalThreshold}
                                    localeCode={localeCode}
                                    bannerText={cartBanner.bannerText}
                                 />
                              </Box>
                           )}
                           <CartLineItems
                              cart={cart.data}
                              testIdPrefix="cart-drawer"
                              borderBottomWidth={1}
                              ImageComponent={ImageComponent}
                              linkComponent={linkComponent}
                              localeCode={localeCode}
                           />
                        </>
                     )}
                     <Fade show={isCartEmpty} disableExitAnimation w="full">
                        <CartEmptyState onClose={onClose} />
                        <Box m="3">
                           <MerchBanner
                              analytics={{ eventType: 'Cart Drawer Merch' }}
                              ImageComponent={ImageComponent}
                              linkComponent={linkComponent}
                              localeCode={localeCode}
                           />
                        </Box>
                     </Fade>
                     <Box p="3" data-testid="cart-drawer-x-sell-items">
                        <CrossSell
                           analytics={{ eventType: 'Cart Drawer Cross Sell' }}
                           crossSellItems={crossSellItems}
                           spacing="3"
                           ImageComponent={ImageComponent}
                           linkComponent={linkComponent}
                           localeCode={localeCode}
                        />
                     </Box>
                  </DrawerBody>

                  <Slide show={cart.data?.hasItemsInCart}>
                     <DrawerFooter
                        borderTopWidth={1}
                        px={{ base: '3', sm: '6' }}
                        display={cart.data?.hasItemsInCart ? 'flex' : 'none'}
                     >
                        <Box w="full">
                           <Collapse show={!cart.isError} mb="3">
                              <Flex w="full" justify="space-between">
                                 <Text fontSize="14px" fontWeight="bold">
                                    Total
                                 </Text>
                                 <Flex direction="column" align="flex-end">
                                    {cart.data && !cart.isRefetching ? (
                                       <>
                                          <Text
                                             color="brand.500"
                                             fontSize="20px"
                                             lineHeight="1em"
                                             fontWeight="bold"
                                             my="0"
                                          >
                                             {formatMoney(cart.data.totals.price, localeCode)}
                                          </Text>
                                          {cart.data.totals.discount &&
                                             cart.data.totals.discount.amount > 0 && (
                                                <Text color="gray.500" my="0">
                                                   {t('CartPage.youSaved')}{' '}
                                                   {formatMoney(
                                                      cart.data.totals.discount,
                                                      localeCode
                                                   )}
                                                </Text>
                                             )}
                                       </>
                                    ) : (
                                       <Skeleton h="20px" w="80px" />
                                    )}
                                 </Flex>
                              </Flex>
                           </Collapse>
                           <SimpleGrid columns={2} spacing="2.5" w="full">
                              <Button
                                 as="a"
                                 href={viewCartUrl}
                                 variant="outline"
                                 onClick={onViewCart}
                              >
                                 {t('CartPage.viewCart')}
                              </Button>
                              <ShopifyCheckoutButton />
                           </SimpleGrid>
                        </Box>
                     </DrawerFooter>
                  </Slide>
               </DrawerContent>
            </Drawer>
         )}
      </>
   );
}
